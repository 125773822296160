<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="PageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="NavigateUserProfile(0)"
          >
            Add Users
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="handleSearch"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableProfiles"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      @sort-changed="onSortChange"
    >
      <!-- Column: Patient -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              class="border"
              size="32"
              :text="avatarText(data.value)"
              button
              style="color: #262262 !important; background-color: rgba(38, 34, 98, 0.1) !important;"
              @click="NavigateUserProfile(data.item.IdUser)"
            />
          </template>
          <span class="font-weight-bold text-nowrap">
            {{ data.value }}
          </span>
        </b-media>
      </template>

      <!-- Column: DOB -->
      <template #cell(Username)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(Profile)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: PhoneNumber -->
      <template #cell(Phone)="data">
        <span class="text-nowrap">
          <feather-icon
            icon="PhoneIcon"
            size="16"
            class="mx-1"
          />
          {{ data.value }}
        </span>
      </template>

      <template #cell(Actions)="data">
        <span class="text-nowrap">
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="NavigateUserProfile(data.item.IdUser)"
          />

          <feather-icon
            icon="TrashIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="DeleteUserProfile(data.item.IdUser)"
          />

          <b-button
            variant="outline-primary"
            class="btn-icon"
            size="sm"
            :style="data.item.Bypass ? {'color': '#262262' , 'background-color': 'white'} : {'color': 'white', 'background-color': '#262262'}"
            @click="EditByPass(data.item.IdUser)"
          >
            <feather-icon :icon="data.item.Bypass ? 'UnlockIcon' : 'LockIcon'" />
          </b-button>
        </span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BPagination, BTable, BMedia, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import userService from '@core/services/users/userService'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    vSelect,
    BTable,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      fields: [{ key: 'Name', label: 'Name', sortable: true }, { key: 'Username', sortable: true }, { key: 'Profile', label: 'Access Level', sortable: true }, { key: 'Phone', label: 'Phone Number', sortable: true }, { key: 'Actions' }],
      items: [],
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      avatarText,
    }
  },
  watch: {
    perPage() {
      this.getUsers()
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      userService.getUsers({
        page: this.actualpage,
        rows: this.perPage,
        name: this.searchTerm,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
      }).then(response => {
        this.items = response.data.users
        this.FullCountEntries = response.data.users[0].full_count
        this.numPages = response.data.users[0].pages
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
    },
    DeleteUserProfile(id) {
      Swal.fire({
        title: 'Attention',
        text: '¿Do you want to delete this user?',
        icon: 'warning',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then(result => {
        if (result.isConfirmed) {
          userService.deleteUserProfile({
            id,
          }).then(() => {
            this.getUsers()
            Swal.fire(
              'Success',
              'User Deleted!',
              'success',
            )
          })
        }
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getUsers()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getUsers()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getUsers()
    },
    NavigateUserProfile(id) {
      this.$store.commit('user-profile/setIdUserProfile', id)
      this.$router.push({ name: 'users-profile' })
    },
    EditByPass(id) {
      userService.editByPass({
        id,
      }).then(() => {
        this.getUsers()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
