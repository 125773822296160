import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class UserleService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getUsers(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUsers, ...args)
    }

    getUser(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUser, ...args)
    }

    getUserProfiles(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserProfiles, ...args)
    }

    getUserTeams(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserTeams, ...args)
    }

    updateUser(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.updateUser, ...args)
    }

    signUpUser(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.signUpUser, ...args)
    }

    deleteUserProfile(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.deleteUserProfile, ...args)
    }

    editByPass(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.editByPass, ...args)
    }

    getUserSpecialPermissions(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserSpecialPermissions, ...args)
    }

    editUserPermission(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.editUserPermission, ...args)
    }
}

function useUserleService() {
  const userService = new UserleService()

  return {
    userService,
  }
}

const { userService } = useUserleService()
export default userService
